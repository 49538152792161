import React from 'react'
import { useStaticQuery, graphql } from "gatsby";


export const GetURL = (id,menus) => {
  const data = menus

	let PageURL = data.filter(item => item.node.strapiId === id).pop().node;


 	let URL = PageURL.URL

  	if((PageURL.Main_Parent != null) &&(PageURL.Sub_Parent == null)) {
    	URL = PageURL.Main_Parent.URL + '/' +URL
  	}

  	else if((PageURL.Main_Parent != null) &&(PageURL.Sub_Parent != null)) {
    	URL = PageURL.Main_Parent.URL + '/' + PageURL.Sub_Parent.URL + '/' +URL
  	}

  	if(URL === 'property/new-homes/for-sale') {
  		URL = 'property/new-homes/for-sale/in-london/'
  	}
	else if(URL === 'property/commercial/for-sale') {
  		URL = 'property/commercial/for-sale/in-london/'	
  	}

	return URL;

}
