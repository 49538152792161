import { Link } from "@StarberryUtils";
import React from "react";
import { useStaticQuery, graphql } from "gatsby";
//import ScrollAnimation from 'react-animate-on-scroll';
import {Row, Col, Container,Breadcrumb } from 'react-bootstrap';
import  "./Breadcrumb.scss";
//import "animate.css/animate.min.css";

import { GetURL } from "../../common/site/functions";

const NewsBreadcrumbs =(props) => {

const data = useStaticQuery(graphql`
query {
allStrapiAllMenus {
edges {
  node {
    URL
    Alias
    Label
    Sub_Parent {
      Label
      Alias
      URL
      id
    }
    Main_Parent {
      Label
      Alias
      URL
      id
    }
    Link_Type
    id
    strapiId
  }
}
}
}
`);

let PageURL = data.allStrapiAllMenus.edges.filter(item => item.node.URL === props.url).pop().node;

let main_parent_url = ''
if(PageURL.Main_Parent !== null)
{
main_parent_url = GetURL(PageURL.Main_Parent.id)
}

let sub_parent_url = ''
if(PageURL.Sub_Parent !== null)
{
sub_parent_url = GetURL(PageURL.Sub_Parent.id)
}

const categories = ["Shops", "Restaurants/Bars & Cafes", "Clubs & Events"]

    //console.log("sfsdgsdfg", props, PageURL, categories.includes(props.category))
    return(
      <div className={`breadcrumb-wrp ${props.class ? props.class : ''} components--MgServices--Breadcrumb--news-breadcrumb`}>
        <Breadcrumb>
          <Breadcrumb.Item className="text-uppercase"><Link to="/">home</Link></Breadcrumb.Item>

          {PageURL.Main_Parent !== null &&
          <Breadcrumb.Item className="text-uppercase"><Link to={`/${main_parent_url}`}>{PageURL.Main_Parent.Label}</Link></Breadcrumb.Item>
          }

          {PageURL.Sub_Parent !== null &&
          <Breadcrumb.Item className="text-uppercase"><Link to={`/${sub_parent_url}`}>{PageURL.Sub_Parent.Label}</Link></Breadcrumb.Item>
          }

          {props.tag == 'person' || props.tag == 'contact' ? <Breadcrumb.Item className="text-uppercase" active><Link to="../">{PageURL.Label}</Link></Breadcrumb.Item> : props.tag != 'local-main-landing' && !categories.includes(props.category) ? <Breadcrumb.Item className="text-uppercase" active><Link to="../../">{PageURL.Label}</Link></Breadcrumb.Item> : ""}
          {categories.includes(props.category) && <Breadcrumb.Item className="text-uppercase" active><Link to="../../../">{PageURL.Label}</Link></Breadcrumb.Item> }

          {props.tag == 'local-main-landing' && <Breadcrumb.Item className="text-uppercase" active><Link to="../../">{PageURL.Label}</Link></Breadcrumb.Item>  }
          {props.tag == 'local-main-landing' && <Breadcrumb.Item className="text-uppercase" active><Link to="../">{"Loving Life"}</Link></Breadcrumb.Item>  }
          {props.category && props.category != "news" && props.tag == 'local-main-landing' ? <Breadcrumb.Item className="text-uppercase" active><Link to="../">{"Loving Life"}</Link></Breadcrumb.Item> : ''}
          
          {categories.includes(props.category) && <Breadcrumb.Item className="text-uppercase" active><Link to="../../">{"Loving Life"}</Link></Breadcrumb.Item> }

          {props.category && props.category != "news" ? <Breadcrumb.Item className="text-uppercase" active><Link to="../">{props.category}</Link></Breadcrumb.Item> : '' }

          {props.sub_category == true ? <Breadcrumb.Item className="text-uppercase" active><Link to="../">{props.sub_category_name}</Link></Breadcrumb.Item> : '' }

          {props.Title ? <Breadcrumb.Item className="text-uppercase" active>{props.Title}</Breadcrumb.Item> : ''}
        </Breadcrumb>
        
      </div>
    )
    }

export default NewsBreadcrumbs;

