import { Link } from "@StarberryUtils";
import React from "react";
import Slider from 'react-slick';
import {Col, Row, Container } from 'react-bootstrap';
import  "./InterestedVideo.scss";
import VideoPlay from "../../Play/custom-video";

import { GetURL } from "../../common/site/functions-update";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";

// import { inViewOptions } from '../../utils/animations';
// import { motion } from "framer-motion";
// import { InView } from "react-intersection-observer";

// const containerVariants = {
// 	hidden: {
// 	  opacity: 0,
// 	  y: -30,
// 	},
// 	visible: {
// 	  opacity: 1,
// 	  y: 0,
// 	  transition: {
// 		ease: [0.33, 1, 0.68, 1],
// 		duration: 0.4
// 	  }
// 	}
// }

const InterestedVideo=(props)=>{

  const settings =  {
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    infinite: true,
    arrows: false,
    autoplay: true,
    
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  // document.documentElement.style.setProperty('--animate-duration', '2s');

  return(
    <section className="case-studies interested-video">
    {props.videos.length !== 0 ? 
      <Container>
      <h2 className="sec-title text-center text-md-center">Other videos that may interest you</h2>
        <Row>
          <Col md={12}>
            <Slider className="study-slider"  {...settings} >

          {props.videos.map(({node}, i) => {
              let url = ""
              if(node.Category) {
              url = GetURL(node.Category.Choose_Category_Base_URL,props.menus)
              }
              else {
              url = "/news-and-guides/videos/"+ node.Videos_Parent_Category.URL
              }

              const image_url = node.Tile_Image.internal.description ? node.Tile_Image.internal.description.replace("File ","").replace('"','').replace('"','') : ''; 

              let processedImages = JSON.stringify({});
              if (node?.imagetransforms?.Tile_Image_Transforms) {
                  processedImages = node.imagetransforms.Tile_Image_Transforms;
              }

            return (
                <div className="started-details ">
                  <div className="related-video-image-link">
                  <Link to={`${url}/${node.URL}`}>
                    <figure className="study-img">

                    <ImageTransform imagesources={image_url} renderer="srcSet" imagename="videos.Tile_Image.tile_1" attr={{ alt: node.Title+ ' - Martyn Gerrard', class:'' }} imagetransformresult={processedImages} id={node.strapiId ? node.strapiId : node.id}/>

                    </figure>
                  </Link>
                      {node.Embed_Video_URL ? <VideoPlay title={node.Title} url={node.Embed_Video_URL}/> : '' }
                  </div>
                  <Link to={`${url}/${node.URL}`}><strong className="study-title d-block">{node.Title}</strong></Link>
                  {node.description && node.description !== " " && <Link to={`${url}/${node.URL}`}><p className="news-description">{node.description}</p></Link>}   
                </div>
            )
          })}

            </Slider>
          </Col>
        </Row>
      </Container> : '' }
    </section>
  )
}

export default InterestedVideo
