import React from "react";
import {Row, Col, Container } from 'react-bootstrap';
import  "./StaticSubscribe.scss";
import BgShadowWhite from "../../../images/bg-shadow-white2.svg";
import VideoPlay from "../../Play/custom-video";
import LoadExternalScript from "../../utils/load-external-script";
import NewsletterForm from '../../forms/newsletter-form'
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";

import { inViewOptions } from '../../utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const containerVariants = {
	hidden: {
	  opacity: 0,
	  y: -30,
	},
	visible: {
	  opacity: 1,
	  y: 0,
	  transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 0.4
	  }
	}
}

const StaticSubscribe=(props)=> {

  const locRatingScriptsJs = 'https://apis.google.com/js/platform.js';


  const image_url = props.image?.internal?.description ? props.image?.internal?.description?.replace("File ","").replace('"','').replace('"','') : ''; 

  let processedImages = JSON.stringify({});
  if (props.data?.imagetransforms?.Video_Background_Image_Transforms) {
      processedImages = props.data.imagetransforms.Video_Background_Image_Transforms;
  }
  
    return(
      <InView {...inViewOptions}>
				{({ ref, inView }) => (
          <>
            <LoadExternalScript 
                src={locRatingScriptsJs}
                // async="true"
                defer="true"
                // appendScriptTo="head"
            />
            <motion.div
              className="subscribe-card static-subs justify-content-between align-items-center"
              ref={ref}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={containerVariants}
            >
                <Container className="">
                  <div className="subscribe-video">
                    
                    <ImageTransform imagesources={image_url} renderer="srcSet" imagename="videos.Video_Background_Image.detail" attr={{ alt: props.data.Title+ ' - Martyn Gerrard', class:'' }} imagetransformresult={processedImages} id={props.data.strapiId}/>

                    {props.url ? <VideoPlay url={props.url}/> : '' }
                  </div>
                  <div className="d-xl-flex align-items-start wrap-subs justify-content-between">
                  <Row>
                    <Col lg={6}>
                      <h2 className="sec-title">Subscribe to our <span>newsletter<img loading="lazy" className="shadow-img shadow2" src={BgShadowWhite} alt="underline"/></span> to get updates straight to your inbox.</h2>
                    </Col>
                    <Col lg={6}>
                      <NewsletterForm />
                    </Col>
                  </Row>
                  </div> 
                  <div className="youtube-subs d-md-flex">
                  <div class="g-ytsubscribe" data-channelid="UC-NFU8ObafEywWNOCDPRkLg" data-layout="default" data-count="default"></div>
                    <p className="subs-desc">Subscribe to our YouTube channel to get notified of all our new content.</p>
                  </div>
                </Container>
          </motion.div>
        </>
      )}
    </InView>
  )
}
export default StaticSubscribe
